import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "tooltip",
      "style": {
        "position": "relative"
      }
    }}>{`Tooltip`}</h1>
    <p>{`Tooltips fornecem informações adicionais sobre foco ou hover. Geralmente contêm texto de ajuda que é contextual a algum elemento. Tooltips não recebem o foco de entrada.`}</p>
    <h2 {...{
      "id": "boas-praticas",
      "style": {
        "position": "relative"
      }
    }}>{`Boas práticas`}</h2>
    <ul>
      <li parentName="ul">{`Devem conter apenas texto de leitura. O uso de elementos interativos como botões e links não é recomendado.`}</li>
      <li parentName="ul">{`A posição deve ser flexível e mudar de acordo com a proximidade do componente com a borda da tela.`}</li>
    </ul>
    <h2 {...{
      "id": "exemplos",
      "style": {
        "position": "relative"
      }
    }}>{`Exemplos`}</h2>
    <Demo src='pages/components/tooltip/TooltipDefault' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      